var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-tune", title: "Simulação de Ocupação" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return undefined
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "5" } },
            [
              _vm.isAnima()
                ? _c(
                    "v-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("sys-select-brand", {
                            attrs: {
                              model: _vm.brand_id,
                              label: "Marca*",
                              "auto-complete": ""
                            },
                            on: {
                              "update:model": function($event) {
                                _vm.brand_id = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("sys-select-campus", {
                        attrs: {
                          model: _vm.campuses,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.campuses,
                          label: "Campus*",
                          multiple: "",
                          "brand-id": _vm.brand_id,
                          "filter-relation": "ambiences"
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.campuses = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("sys-select-ambience-type", {
                        attrs: {
                          model: _vm.ambienceTypes,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.ambienceTypes,
                          label: "Tipo de Ambiente*",
                          multiple: "",
                          "select-all": "",
                          "campus-id": _vm.campus_ids,
                          dense: ""
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.ambienceTypes = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("sys-select-work-load-type", {
                        attrs: {
                          model: _vm.workLoadTypes,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.workLoadTypes,
                          label: "Tipo de Carga Horária*",
                          multiple: "",
                          "select-all": "",
                          dense: ""
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.workLoadTypes = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("v-range-slider", {
                        staticClass: "align-center",
                        attrs: {
                          min: _vm.min,
                          max: _vm.max,
                          hint: "Capacidade do Ambiente",
                          "persistent-hint": "",
                          "thumb-label": "",
                          dense: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticStyle: { width: "50px" },
                                  attrs: {
                                    value: _vm.capacity[0],
                                    "hide-details": "",
                                    "single-line": "",
                                    min: _vm.min,
                                    max: _vm.max,
                                    dense: "",
                                    type: "number"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(_vm.capacity, 0, $event)
                                    }
                                  }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticStyle: { width: "50px" },
                                  attrs: {
                                    value: _vm.capacity[1],
                                    "hide-details": "",
                                    "single-line": "",
                                    min: _vm.min,
                                    max: _vm.max,
                                    dense: "",
                                    type: "number"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(_vm.capacity, 1, $event)
                                    }
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.capacity,
                          callback: function($$v) {
                            _vm.capacity = $$v
                          },
                          expression: "capacity"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("v-range-slider", {
                        staticClass: "align-center",
                        attrs: {
                          min: _vm.min,
                          max: _vm.max,
                          hint: "Número de Alunos",
                          "persistent-hint": "",
                          color: "orange darken-3",
                          "thumb-label": "",
                          dense: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticStyle: { width: "50px" },
                                  attrs: {
                                    value: _vm.students[0],
                                    "hide-details": "",
                                    "single-line": "",
                                    min: _vm.min,
                                    max: _vm.max,
                                    dense: "",
                                    type: "number"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(_vm.students, 0, $event)
                                    }
                                  }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-text-field", {
                                  staticClass: "mt-0 pt-0",
                                  staticStyle: { width: "50px" },
                                  attrs: {
                                    value: _vm.students[1],
                                    "hide-details": "",
                                    "single-line": "",
                                    min: _vm.min,
                                    max: _vm.max,
                                    dense: "",
                                    type: "number"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$set(_vm.students, 1, $event)
                                    }
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.students,
                          callback: function($$v) {
                            _vm.students = $$v
                          },
                          expression: "students"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2 white--text",
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.loading,
                            color: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.getData()
                            }
                          }
                        },
                        [
                          _vm._v(" Pesquisar "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v(" search ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    filled: "",
                                    rounded: "",
                                    label: "Total de Ambientes",
                                    placeholder: "Informe o número de Ambientes"
                                  },
                                  model: {
                                    value: _vm.data.total_ambiences,
                                    callback: function($$v) {
                                      _vm.$set(_vm.data, "total_ambiences", $$v)
                                    },
                                    expression: "data.total_ambiences"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    filled: "",
                                    rounded: "",
                                    label: "Dias por semana",
                                    placeholder:
                                      "Informe o número de dias por semana"
                                  },
                                  model: {
                                    value: _vm.data.total_days,
                                    callback: function($$v) {
                                      _vm.$set(_vm.data, "total_days", $$v)
                                    },
                                    expression: "data.total_days"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { attrs: { width: "5%" } }),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v("Horários por turno")
                            ]),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v("Aulas por turno")
                            ]),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v("Ocupação ideal por dia")
                            ])
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.shifts, function(shift) {
                            return _c("tr", { key: shift.id }, [
                              _c("td", [_vm._v(_vm._s(shift.name))]),
                              _c(
                                "td",
                                { attrs: { width: "20%" } },
                                [
                                  _c("v-text-field", {
                                    staticClass:
                                      "centered-input text--darken-3 mt-1",
                                    attrs: {
                                      regular: "",
                                      dense: "",
                                      filled: "",
                                      rounded: ""
                                    },
                                    model: {
                                      value: _vm.data[shift.field_hour],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          shift.field_hour,
                                          $$v
                                        )
                                      },
                                      expression: "data[shift.field_hour]"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { attrs: { width: "20%" } },
                                [
                                  _c("v-text-field", {
                                    staticClass:
                                      "centered-input text--darken-3  mt-1",
                                    attrs: {
                                      dense: "",
                                      regular: "",
                                      filled: "",
                                      rounded: ""
                                    },
                                    model: {
                                      value: _vm.data[shift.field_class],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          shift.field_class,
                                          $$v
                                        )
                                      },
                                      expression: "data[shift.field_class]"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      value: _vm.getPercent(shift.id),
                                      color: _vm.getColor(
                                        _vm.getPercent(shift.id)
                                      ),
                                      striped: "",
                                      height: "40"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var value = ref.value
                                            return [
                                              _vm.getTotalItems(shift.id) > 0
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _vm.byPercent
                                                                  ? _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticStyle: {
                                                                              cursor:
                                                                                "help"
                                                                            }
                                                                          },
                                                                          "span",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            Math.ceil(
                                                                              value
                                                                            )
                                                                          ) +
                                                                            "% "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticStyle: {
                                                                              cursor:
                                                                                "help"
                                                                            }
                                                                          },
                                                                          "span",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .getTotalItems(
                                                                                shift.id
                                                                              )
                                                                              .toFixed(
                                                                                2
                                                                              )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm.byPercent
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .getTotalItems(
                                                                      shift.id
                                                                    )
                                                                    .toFixed(2)
                                                                ) +
                                                                " aula(s) "
                                                            )
                                                          ])
                                                        : _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  Math.ceil(
                                                                    value
                                                                  )
                                                                ) +
                                                                "% de Ocupação "
                                                            )
                                                          ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          0
                        ),
                        _c("tfoot", [
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: "3" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { row: "" },
                                    model: {
                                      value: _vm.periodicity,
                                      callback: function($$v) {
                                        _vm.periodicity = $$v
                                      },
                                      expression: "periodicity"
                                    }
                                  },
                                  [
                                    _c("v-radio", {
                                      attrs: { label: "Semanal", value: "week" }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        label: "Quinzenal",
                                        value: "week2",
                                        color: "orange"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm.data
                                  ? _c("v-switch", {
                                      staticClass: "ml-1",
                                      attrs: {
                                        inset: "",
                                        label: "Exibir percentual"
                                      },
                                      model: {
                                        value: _vm.byPercent,
                                        callback: function($$v) {
                                          _vm.byPercent = $$v
                                        },
                                        expression: "byPercent"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }